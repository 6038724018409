import Bluebird from 'bluebird'
import { fetchTitlesForPage, isRequiresTitleMapOnPage } from '@/page/TitleCollection'
import {
  getBrandLogos,
  getCollectionDataSources,
  getStartWatchingEpisodes,
  isFranchiseComponent,
  PageDataContext,
} from '@/services/RenderService'
import { Page } from '@/types/codegen-contentful'
import { isStagingEnvironment } from '@/utils/environment-utils'
import { logger } from '@/utils/logging'
import { omitUndefineds } from '@/utils/object'
import { createWebClient } from '../ApolloClient'
import { getCollection, SNEAK_PEEK_ENTRY_ID } from '../CmsService/Collection'
import { getComingSoonCollection } from '../ComingSoonService'
import {
  CatalogTitle,
  CatalogTitleMap,
  findHydraExternalId,
  findTitleProjectSlug,
  formatTitleMap,
  isTitleLinkedToEpisodeWatchPage,
} from '../ContentCatalog'
import { getFranchiseBySlug } from '../FranchiseService'
import { getEpisode, getProjectAbout } from '../ProjectsService'

export interface PageDataContextResult extends PageDataContext {
  allQueriesSucceeded: boolean
}

export async function fetchPageDataContext(
  page: Page,
  preview: boolean = false,
  locale: string = 'en',
): Promise<PageDataContextResult | null> {
  if (!page.contentCollection?.items || page.contentCollection?.items.length === 0) {
    return {
      allQueriesSucceeded: true,
      preview,
    }
  }

  const pageContext = await fetchDataSources(page, locale, preview)
  return omitUndefineds(pageContext)
}

// eslint-disable-next-line sonarjs/cognitive-complexity
async function fetchDataSources(page: Page, locale: string, preview: boolean): Promise<PageDataContextResult> {
  const dataSources = getCollectionDataSources(page)
  const pageContext: PageDataContextResult = {
    allQueriesSucceeded: true,
    preview,
  }

  for (const dataSource of dataSources) {
    if (dataSource === 'brand-logos') {
      const brandLogos = await getBrandLogos({ analytics: { page } })
      pageContext['brand-logos'] = brandLogos
      if (brandLogos.length === 0) pageContext.allQueriesSucceeded = false
    }
    if (dataSource === 'coming-soon') {
      const upcomingTabProjects = await getComingSoonCollection()
      pageContext['coming-soon-tab'] = upcomingTabProjects
      if (upcomingTabProjects.length === 0) pageContext.allQueriesSucceeded = false
    } else if (dataSource === 'early-access') {
      const sneakPeekProjects = await getCollection({ locale, preview }, SNEAK_PEEK_ENTRY_ID)
      pageContext['sneak-peeks'] = sneakPeekProjects
      if (!sneakPeekProjects) pageContext.allQueriesSucceeded = false
    } else if (dataSource === 'start-watching') {
      const startWatchingEpisodes = await getStartWatchingEpisodes({ analytics: { page } })
      pageContext['start-watching'] = startWatchingEpisodes
      if (startWatchingEpisodes.length === 0) pageContext.allQueriesSucceeded = false
    }
  }

  const franchiseComponent = page.contentCollection?.items.find(isFranchiseComponent)
  if (franchiseComponent && franchiseComponent.franchiseSlug) {
    const franchise = await getFranchiseBySlug(franchiseComponent.franchiseSlug, true)
    if (franchise) {
      pageContext['franchiseComponent'] = franchiseComponent
      pageContext['franchise'] = franchise
    } else {
      pageContext.allQueriesSucceeded = false
    }
  }

  if (isRequiresTitleMapOnPage(page)) {
    const titles = await fetchTitlesForPage(page)
    pageContext['title-map'] = await getValidatedTitleMap(titles)
    if (titles.length === 0) pageContext.allQueriesSucceeded = false
  }

  return pageContext
}

/**
 *
 * The data coming back from the Content Catalog is, unfortunately, often invalid.
 * We're patching this on the front-end for now by detecting and removing the invalid titles here.
 * @returns
 */
export async function getValidatedTitleMap(titles: CatalogTitle[]): Promise<CatalogTitleMap> {
  const client = createWebClient()

  const validated = await Bluebird.map(
    titles,
    async (title) => {
      const projectSlug = findTitleProjectSlug(title)

      if (!projectSlug) return

      try {
        const guid = findHydraExternalId(title)
        if (guid && isTitleLinkedToEpisodeWatchPage(title)) {
          const response = await getEpisode({ guid, projectSlug }, client)

          if (response && response.episode) {
            return title
          } else {
            reportWarning('episode', title)
            return
          }
        } else {
          const response = await getProjectAbout({ slug: projectSlug }, client)

          if (response && response.slug === projectSlug) {
            return title
          } else {
            reportWarning('project', title)
            return
          }
        }
      } catch (err) {
        reportWarning('episode', title, err as Error)
        return
      }
    },
    { concurrency: 3 },
  )

  return formatTitleMap(validated.filter(Boolean) as CatalogTitle[])
}

function reportWarning(objType: string, title: CatalogTitle, err?: Error) {
  if (!isStagingEnvironment()) {
    logger().warn(`Unable to find a ${objType} from the federated graph for a Content Catalog title.`, { title }, err)
  }
}
